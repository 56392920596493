import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import MessageList from './MessageList';
import MessageCreate from './MessageCreate';
import MessageEdit from './MessageEdit';

export default {
    list: MessageList,
    create: MessageCreate,
    edit: MessageEdit,
    icon: NotificationsActiveIcon,
};
