import UserIcon from '@material-ui/icons/CenterFocusWeak';
import UserList from './CodeList';
// import UserCreate from './UserCreate';
// import UserEdit from './CodeEdit';

export default {
    list: UserList,
    // create: UserCreate,
    // edit: UserEdit,
    icon: UserIcon,
};
