import * as React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    useTranslate,
    required,
} from 'react-admin';
import { Typography } from '@material-ui/core';


const UserCreate = (props: any) => {

    return (
        <Create {...props}>
            <SimpleForm style={{ display: 'flex', flexDirection: 'column' }}>
                <SectionTitle label="resources.messages.fields.create" />
                <TextInput
                    source="content"
                    fullWidth 
                    validate={requiredValidate}
                />

            </SimpleForm>
        </Create>
    );
};

const requiredValidate = [required()];

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};


export default UserCreate;
