import * as React from 'react';
import {
    Edit,
    TextInput,
    useTranslate,
    FormWithRedirect,
    required,
    email,
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';

const UserEdit = (props: any) => {
    return (
        <Edit
            title={"Message"}
            aside={false}
            undoable={false}
            component="div"
            {...props}
        >
            <UserForm />
        </Edit>
    );
};

const formatDate = (dateString) => {
    if (!dateString) return '';

    const date = new Date(dateString);
    return new Intl.DateTimeFormat('fr-FR', {
        year: 'numeric', 
        month: 'numeric', 
        day: 'numeric',
        hour: '2-digit', 
        minute: '2-digit',
        second: '2-digit', // Ajoutez ceci si vous voulez les secondes
    }).format(date);
};


const UserForm = (props: any) => {
    const translate = useTranslate();

    return (
        <FormWithRedirect
            {...props}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.messages.name'
                                        )}
                                    </Typography>

                                    <TextInput
                                        type="content"
                                        source="content"
                                        resource="messages"
                                        disabled
                                        label="resources.messages.fields.content"
                                        validate={[email(), required()]}
                                        fullWidth
                                    />

                                    <TextInput
                                        source="created_at"
                                        disabled
                                        label="resources.users.fields.created_at"
                                        format={formatDate}
                                        options={{
                                            hourCycle: 'h23',
                                        }}
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                        {/* <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={false}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="users"
                        /> */}
                    </form>
                </Card>
            )}
        />
    );
};


export default UserEdit;
