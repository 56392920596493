import { TranslationMessages } from 'ra-core';
import frenchMessages from 'ra-language-french';

const customFrenchMessages: TranslationMessages = {
    ...frenchMessages,
    global: {
        auth: {
            email: 'Email',
            sign_in: 'Entrer',
            password: 'Mot de Passe',
            sign_in_error: "Erreur d'Authentification Failed",
        },
    },
    pos: {
        search: 'Rechercher',
        configuration: 'Configuration',
        language: 'Langue',
        theme: {
            name: 'Theme',
            light: 'Clair',
            dark: 'Obscur',
        },
        dashboard: {
            subscriptions_today: {
                title: 'Nouveaux Clients',
                subtitle: "Aujourd'hui",
            },
            subscriptions_total: {
                title: 'Total Clients',
                subtitle: '',
            },
            courses_today: {
                title: 'Nouvelles Courses',
                subtitle: "Aujourd'hui",
            },
            courses_total: {
                title: 'Total courses',
                subtitle: '',
            },
            drivers_online: {
                title: 'Chaufeurs Connectés',
                subtitle: 'Actuellement',
            },
            welcome: {
                title: 'Bienvenue dans Cool2scan',
                subtitle: 'Ceci est le back-office Cool2scan..',
                aor_button: 'Site web de Cool2scan',
                demo_button: 'Code source de cette démo',
            },
        },
        menu: {
            sales: 'Ventes',
            prducts: 'Produits',
            users: 'Utilisateurs',
            delivery: 'Livraisons',
        },
        actions: {
            concel: 'Annuler',
        },
    },
    resources: {
        users: {
            name: 'Client |||| Clients',
            fields: {
                name: 'Nom Prénom',
                nameFirst: 'Prénom',
                nameLast: 'Nom',
                society_info: { name: 'Le nom de la société', email : 'Email de la société' },
                email: 'Email',
                phone: 'Tél',
                civility: 'Genre',
                logo: "Logo de la société",
                totalCoues: "Nbr de codes créés",
                count_scans: "Nbr de codes créés",
                count_subscriptions: "Nbr de code PRO",
                count_messages: "Nbr de messages envoyés",
                address1: 'Adresse',
                city: 'Ville',
                country: 'Pays',
                birthdate: 'date de Naissance',
                socialReason: 'Raison Sociale',
                created_at: "Date d'inscription",
                updated_at: 'Dernière mise à jour',
                account_enabled: 'Activé',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
                nb_courses: 'N de Commandes',
            },
            civilities: {
                monsieur: 'M',
                madame: 'Mme',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                quizzes: 'Quizzes',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        codes: {
            name: 'Code |||| Codes',
            fields: {
                name: 'Nom de code',
                email: 'Email',
                phone: 'Tél',
                civility: 'Genre',
                address1: 'Adresse',
                city: 'Ville',
                isPro: "C'est Pro",
                logo: "Logo de l'organisation",
                logoCode: "Logo du code",
                totalCoues: "Nbr de codes créés",
                count_scans: "Total scanés",
                count_subscriptions: "Total des abonnés",
                count_messages: "Total messages envoyés",
                country: 'Pays',
                birthdate: 'date de Naissance',
                socialReason: 'Raison Sociale',
                created_at: "Date d'inscription",
                updated_at: 'Dernière mise à jour',
                account_enabled: 'Activé',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
                nb_courses: 'N de Commandes',
            },
            civilities: {
                monsieur: 'M',
                madame: 'Mme',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                quizzes: 'Quizzes',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        drivers: {
            name: 'Chauffeur |||| Chauffeurs',
            fields: {
                name: 'Nom Prénom',
                email: 'Email',
                phone: 'Tél',
                civility: 'Genre',
                address1: 'Adresse',
                city: 'Ville',
                country: 'Pays',
                birthdate: 'date de Naissance',
                socialReason: 'Raison Sociale',
                created_at: "Date d'inscription",
                updated_at: 'Dernière mise à jour',
                account_enabled: 'Activé',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
                nb_courses: 'N de Commandes',
            },
            civilities: {
                monsieur: 'M',
                madame: 'Mme',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                quizzes: 'Quizzes',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le Chauffeur',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        admins: {
            name: 'Administrateur |||| Administrateurs',
            fields: {
                name: {
                    first: 'Prénom',
                    last: 'Nom',
                },
                nameFull: 'Nom Complet',
                email: 'Email',
                phone: 'Tél',
                address1: 'Adresse',
                city: 'Ville',
                country: 'Pays',
                socialReason: 'Raison Sociale',
                created_at: 'Crée le',
                updated_at: 'Dernière mise à jour',
                account_enabled: 'Activé',
                commands: 'Commandes',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        operators: {
            name: 'Opérateur |||| Opérateurs',
            fields: {
                name: {
                    first: 'Nom',
                    last: 'Prénom',
                    full: 'Nom Complet',
                },
                email: 'Email',
                phone: 'Tél',
                address1: 'Adresse',
                city: 'Ville',
                country: 'Pays',
                socialReason: 'Raison Sociale',
                created_at: 'Crée le',
                updated_at: 'Dernière mise à jour',
                account_enabled: 'Activé',
                commands: 'Commandes',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        messages: {
            name: 'Notification',
            fields: {
                content: 'Message',
                create: 'Créer un message',
                target: 'Cible',
                targetModel: 'Type',
                category: 'Catégorie',
                feature: 'Feature',
                created_at: 'Date de Création',

            },
            image: {
                upload_several:
                    'Glissez des photos à uploader ou cliquez pour en sélectionner une.',
                upload_single:
                    'Glissez une photo à uploader ou cliquez pour la sélectionner.',
            },
        },
        banners: {
            name: 'Bannière |||| Bannières',
            fields: {
                name: 'Nom',
                image: 'Image',
                target: 'Cible',
                targetModel: 'Type',
                category: 'Catégorie',
                feature: 'Feature',
            },
            image: {
                upload_several:
                    'Glissez des photos à uploader ou cliquez pour en sélectionner une.',
                upload_single:
                    'Glissez une photo à uploader ou cliquez pour la sélectionner.',
            },
        },
        VehiculeTypes: {
            name: 'Type de Véhicule |||| Types de Véhicules',
            fields: {
                name: 'Nom',
                image: 'Image',
                tarif_km: 'Prix sur chaque KM',
                tarif_min: 'Prix sur chaque minute',
                is_enabled: 'Activer',
                created_at: 'Date de Création',
            },
        },
        courses: {
            name: 'Course |||| Courses',
            fields: {
                vehicle_type: 'Type de Véhicule',
                user: 'Client',
                code: 'Numéro',
                code_str: 'Numéro',
                from: 'De',
                from_address: "De l'adresse",
                to: 'Vers',
                to_address: "Vers l'adresse",
                driver: 'Chauffeur',
                notified_drivers: 'Chauffeurs notifiés',
                google_distance: 'Distance Estimée Google',
                google_duration: 'Durée Estimée Google',
                real_distance: 'Distance Réelle',
                real_duration: 'Durée Réelle',
                statut: 'Statut',
                client_note: 'Note client',
                client_comment: 'Commentaire clent',
                price: 'Prix',
                estimated_price: 'Prix Estimé',
                created_at: 'Date de Création',
            },
            course_statut: {
                new: 'Nouvelle',
                in_search: 'En Recherche',
                driver_notified: 'En attente de Chauffeur',
                conceled_by_client: 'Abandonnée par Client',
                conceled_by_driver: 'Abandonnée par Chauffeur',
                accepted: 'Acceptée',
                in_place: 'Chauffeur sur place',
                started: 'Démarrée',
                finished: 'Terminée (Non encore payée)',
                payed: 'Payée',
                not_payed: 'Paiement échoué',
            },
            actions: {
                pay: 'Payer',
            },
        },
        course_events: {
            name: 'Evenement |||| Evenements',
            fields: {
                source: 'Déclanché par',
                to_statut: 'Statut',
                created_at: 'Date de Création',
            },
            source_types: {
                user: 'Client',
                driver: 'Chauffeur',
                operator: 'Opérateur',
                admin: 'Administrateur',
            },
        },
    },
};

export default customFrenchMessages;
