import * as React from 'react';
import { FC } from 'react';
import { Customer, FieldProps } from '../../types';

const API_URL = process.env.REACT_APP_REST_API_URL;

interface Props extends FieldProps<Customer> {
    className?: string;
    size?: string;
}

const Thumbnailfield: FC<Props> = ({ record, size = '25', className }) => {
    // Check if record and record.society_info are defined
    if (record && record.society_info) {
        return (
            <img
                alt=""
                src={`${API_URL}/files/${record.society_info.logo}/view?size=${size}x${size}`}
                style={{ height: parseInt(size, 16) }}
                className={className}
            />
        );
    } else {
        return null;
    }
};

export default Thumbnailfield;
